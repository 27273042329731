import React, { useContext, useState, useCallback } from "react";
import { Stack, Text, Label, Separator, DefaultButton, IconButton } from '@fluentui/react';
import { useLocation, useNavigate } from "react-router-dom";
import { TeamsFxContext } from "../Context";
import DowntimeAlertModal from "../modals/DowntimeAlertModal";
import BackdropLoader from "../modals/BackdropLoader";

function DowntimeAlertDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiClient } = useContext(TeamsFxContext);
    const { alert, currentPage, searchTerm, items } = location.state || {};
    const [isSaving, setIsSaving] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localItems, setLocalItems] = useState(items || []);
    const [localAlert, setLocalAlert] = useState(alert);

    const handleBackClick = useCallback(() => {
        navigate('/downtime-alert', {
            state: {
                currentPage,
                searchTerm,
                items: localItems
            }
        });
    }, [navigate, currentPage, searchTerm, localItems]);

    const handleEditClick = useCallback(() => setIsModalOpen(true), []);

    const handleSaveEdit = async (saveData) => {
        setIsSaving(true);
        try {
            await apiClient.put(`downtimeAlerts?id=${localAlert.id}`, saveData);
            
            // Map the saveData to match the API response structure
            const updatedAlert = {
                ...localAlert,
                name: saveData.username,
                email: saveData.email,
                role: saveData.role,
                alert_threshold_min: saveData.alert_threshold,
                occurrence_threshold_min: saveData.time,
                occurrences: saveData.occurences,
                active: saveData.alerts_enabled
            };

            // Update items list with correctly mapped data
            const newItems = localItems.map(item => 
                item.id === localAlert.id ? updatedAlert : item
            );

            setLocalItems(newItems);
            setLocalAlert(updatedAlert);
        } catch (err) {
            console.error("Error updating alert:", err);
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
        }
    };

    const handleDeleteClick = async () => {
        try {
            setIsSaving(true)
            await apiClient.delete(`downtimeAlerts?id=${localAlert.id}`);

            const newItems = localItems.filter(item => item.id !== localAlert.id);
            const newPage = (newItems.length % 10 === 0) && currentPage > 1 
                ? currentPage - 1 
                : currentPage;
                setIsSaving(false)

            navigate('/downtime-alert', {
                state: {
                    currentPage: newPage,
                    searchTerm,
                    items: newItems
                }
            });
        } catch (err) {
            console.error("Error deleting alert:", err);
        }
    };

    if (!localAlert) return <div>No data available</div>;

    return (
        <>
            <Stack tokens={{ childrenGap: 20, padding: 20 }} 
                   styles={{ root: { backgroundColor: '#FFFFFF', position: 'relative' } }}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                        <IconButton
                            iconProps={{ iconName: 'Back' }}
                            onClick={handleBackClick}
                            title="Back"
                            ariaLabel="Back"
                            style={styles.backButton}
                        />
                        <Text style={styles.textCommon}>Back</Text>
                    </Stack>

                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <DefaultButton 
                            text="Edit" 
                            size="medium" 
                            onClick={handleEditClick} 
                            style={styles.button} 
                        />
                        <DefaultButton 
                            text="Delete" 
                            size="medium" 
                            onClick={handleDeleteClick} 
                            style={styles.button} 
                        />
                    </Stack>
                </Stack>

                <Separator />

                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Name</Label>
                        <Text style={styles.textCommon}>{localAlert.name}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Email</Label>
                        <Text style={styles.textCommon}>{localAlert.email}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Role</Label>
                        <Text style={styles.textCommon}>{localAlert.role}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Alert Threshold (minutes)</Label>
                        <Text style={styles.textCommon}>{localAlert.alert_threshold_min}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Occurrence Threshold (minutes)</Label>
                        <Text style={styles.textCommon}>{localAlert.occurrence_threshold_min}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Occurrences</Label>
                        <Text style={styles.textCommon}>{localAlert.occurrences}</Text>
                    </Stack>

                    <Stack verticalAlign="center">
                        <Label style={styles.labelCommon}>Active</Label>
                        <Text style={styles.textCommon}>
                            {localAlert.active ? "Enabled" : "Disabled"}
                        </Text>
                    </Stack>
                </Stack>



               { !isSaving && <DowntimeAlertModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSaveEdit}
                    userData={localAlert}
                    mode="edit"
                />}

                <Separator />
            </Stack>

            {isSaving && <BackdropLoader />}


        </>
    );
}

const styles = {
    container: {
        childrenGap: 20,
        padding: 20,
        backgroundColor: '#FFFFFF'
    },
    textCommon: {
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: "left",
        color: "#1F1F1F",
        fontSize: 14
    },
    labelCommon: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "left",
        color: "#959593",
        fontSize: 12
    },
    button: {
        border: "1px solid #0072CE"
    },
    backButton: {
        marginRight: 10,
        fontFamily: "Fabric",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 16,
        textAlign: "center",
        color: "#0072CE"
    }
};

export default DowntimeAlertDetails;
