import React, { useState, useEffect, useContext, useCallback } from "react";
import { TeamsFxContext } from "../Context";
import { Navigate, useLocation } from "react-router-dom";
import {
  TableCellLayout,
  Label,
  DataGrid,
  DataGridBody,
  DataGridRow,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  createTableColumn,
  SearchBox,
  Spinner,
  MessageBar,
} from "@fluentui/react-components";
import { IconButton, Text, PrimaryButton } from "@fluentui/react";
import AddUserModal from "../modals/UserModal";
import { ChevronDown20Regular } from "@fluentui/react-icons";
import { Tooltip } from "@fluentui/react-components";

const UserProfile = () => {
  const { teamsUserCredential, apiClient } = useContext(TeamsFxContext);
  const location = useLocation();

  const [userInfo, setUserInfo] = useState({});
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [navigateToDetail, setNavigateToDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownValues, setDropdownValues] = useState({
    userroles: [],
    plants: [],
    areas: [],
    departments: [],
    subareas: []
  });
  const [appliedFilters, setAppliedFilters] = useState({
    shifts: [],
    footprints: [],
    users: [],
    downtimeMin: "",
    downtimeMax: "",
    alarmMin: "",
    alarmMax: ""
  });
  const [teamsUserEmail, setTeamsUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");

  const processItems = useCallback((items) => {
    const groupedItems = items.reduce((acc, item) => {
      const key = `${item.gmin}_${item.name}_${item.user_email}`;

      if (!acc[key]) {
        acc[key] = {
          ...item,
          userrole_id: [item.userrole_id],
          plant_id: [item.plant_id],
          area_id: [item.area_id],
          department_id: [item.department_id],
          shift: [item.shift],
          subarea_id: [item.subarea_id],
          allIds: [item.id]
        };
      } else {
        acc[key].userrole_id.push(item.userrole_id);
        acc[key].plant_id.push(item.plant_id);
        acc[key].area_id.push(item.area_id);
        acc[key].department_id.push(item.department_id);
        acc[key].shift.push(item.shift);
        acc[key].subarea_id.push(item.subarea_id);
        acc[key].allIds.push(item.id);
      }
      return acc;
    }, {});

    return Object.values(groupedItems);
  }, []);

  const fetchItems = useCallback(async () => {
    try {
      const response = await apiClient.get("userProfile");
      const values = await apiClient.get('getDefaultValues');
      setDropdownValues(values.data.data);

      const processedItems = processItems(response.data.users || []);
      setItems(processedItems);
    } catch (err) {
      console.error("Error fetching users:", err);
      setErrorMessage("Failed to fetch users. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [apiClient, processItems]);

  useEffect(() => {
    const initUserInfo = async () => {
      try {
        const userInfo = await teamsUserCredential.getUserInfo();
        console.log(userInfo, 'user')
        setTeamsUserEmail(userInfo?.preferredUserName);
        setUserInfo(userInfo);
        const fetchUsers = async () => {
          try {
            const response = await apiClient.get('getUserListUnFiltered');
            const currentUser = response.data.users.find(user => 
              user.user_email === userInfo?.preferredUserName
            );
            setUserRole(currentUser?.role || "");
          } catch (error) {
            console.error('Error fetching users:', error);
          }
        };
        await fetchUsers();
        
        const values = await apiClient.get('getDefaultValues');
        setDropdownValues(values.data.data);

        const state = location.state;
        if (state?.items) {
          setItems(state.items);
          setAppliedFilters(state.appliedFilters);
          setCurrentPage(state.currentPage || 1);
          setSearchTerm(state.searchTerm || "");
        } else {
          await fetchItems();
        }
      } catch (err) {
        console.error("Error initializing or fetching data:", err);
        setErrorMessage("Error fetching data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    initUserInfo();
  }, [teamsUserCredential, location.state, apiClient, fetchItems]);

  const getUserRoleName = useCallback((roleId) => {
    const role = dropdownValues?.userroles.find(r => r.userrole_id === roleId);
    return role ? role.userrole_name : roleId;
  }, [dropdownValues?.userroles]);

  const getPlantName = useCallback((plantId) => {
    const plant = dropdownValues.plants.find(p => p.plant_id === plantId);
    return plant ? plant.plant_cd : plantId;
  }, [dropdownValues?.plants]);

  const getAreaName = useCallback((areaId) => {
    const area = dropdownValues?.areas.find(a => a.area_id === areaId);
    return area ? area.area : areaId;
  }, [dropdownValues?.areas]);

  const getDepartmentName = useCallback((deptId) => {
    const dept = dropdownValues?.departments.find(d => d.department_id === deptId);
    return dept ? dept.department : deptId;
  }, [dropdownValues?.departments]);

  const getSubAreaName = useCallback((subareaId) => {
    const subarea = dropdownValues?.subareas.find(s => s.subarea_id === subareaId);
    return subarea ? subarea.sub_area : subareaId;
  }, [dropdownValues?.subareas]);

  const EmailCell = ({ email }) => {
    const MAX_LENGTH = 15;
    const isEmailLong = email.length > MAX_LENGTH;
    const displayEmail = isEmailLong ? `${email.substring(0, MAX_LENGTH)}...` : email;

    return (
      <TableCellLayout style={styles.cellContent}>
        <Tooltip
          content={<div style={styles.tooltipContent}>{email}</div>}
          relationship="label"
          withArrow
          visible={isEmailLong ? undefined : false}
        >
          <div style={styles.emailCell}>
            {displayEmail}
          </div>
        </Tooltip>
      </TableCellLayout>
    );
  };

  const createColumns = useCallback(() => [
    {
      id: "gmin",
      label: "GMIN ID ",
      width: "100px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {item.gmin}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "name",
      label: "User Name ",
      width: "150px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {item.name}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "user_email",
      label: "User Email ",
      width: "200px",
      renderCell: (item) => (
        <EmailCell email={item.user_email} />
      )
    },
    {
      id: "userrole_id",
      label: "User Role ",
      width: "200px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {Array.isArray(item.userrole_id)
              ? item.userrole_id
                  .filter(roleId => roleId !== null && roleId !== undefined)
                  .map(roleId => getUserRoleName(roleId))
                  .filter(name => name)
                  .join(', ')
              : getUserRoleName(item.userrole_id)}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "plant_id",
      label: "Plant ",
      width: "150px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {Array.isArray(item.plant_id)
              ? item.plant_id
                .filter(plantId => plantId !== null && plantId !== undefined)
                .map(plantId => getPlantName(plantId))
                .filter(name => name)
                .join(', ')
              : getPlantName(item.plant_id)}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "area_id",
      label: "Area ",
      width: "150px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {Array.isArray(item.area_id)
              ? item.area_id
                .filter(areaId => areaId !== null && areaId !== undefined)
                .map(areaId => getAreaName(areaId))
                .filter(name => name)
                .join(', ')
              : getAreaName(item.area_id)}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "department_id",
      label: "Department ",
      width: "200px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {Array.isArray(item.department_id)
              ? item.department_id
                .filter(deptId => deptId !== null && deptId !== undefined)
                .map(deptId => getDepartmentName(deptId))
                .filter(name => name)
                .join(', ')
              : getDepartmentName(item.department_id)}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "shift",
      label: "Shift ",
      width: "100px",
      renderCell: (item) => {
        const shiftMap = {
          1: '1',
          2: '2',
          3: '3'
        };
        return (
          <TableCellLayout style={styles.cellContent}>
            <div style={styles.wrappingCell}>
              {Array.isArray(item.shift)
                ? item.shift
                  .filter(shiftId => shiftId !== null && shiftId !== undefined)
                  .map(shiftId => shiftMap[shiftId])
                  .filter(name => name)
                  .join(', ')
                : shiftMap[item.shift] || ''}
            </div>
          </TableCellLayout>
        );
      }
    },
    {
      id: "subarea_id",
      label: "Sub Area ",
      width: "200px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {Array.isArray(item.subarea_id)
              ? item.subarea_id
                .filter(subareaId => subareaId !== null && subareaId !== undefined)
                .map(subareaId => getSubAreaName(subareaId))
                .filter(name => name)
                .join(', ')
              : getSubAreaName(item.subarea_id)}
          </div>
        </TableCellLayout>
      )
    },
    {
      id: "created_at",
      label: "Created at",
      width: "150px",
      renderCell: (item) => (
        <TableCellLayout style={styles.cellContent}>
          <div style={styles.wrappingCell}>
            {item.created_at}
          </div>
        </TableCellLayout>
      )
    },
  ].map((column) =>
    createTableColumn({
      columnId: column.id,
      compare: (a, b) => {
        if (Array.isArray(a[column.id])) {
          const aValue = a[column.id].find(val => val !== null && val !== undefined) || '';
          const bValue = Array.isArray(b[column.id])
            ? b[column.id].find(val => val !== null && val !== undefined) || ''
            : (b[column.id] || '');
          return String(aValue).localeCompare(String(bValue));
        } else if (Array.isArray(b[column.id])) {
          const aValue = a[column.id] || '';
          const bValue = b[column.id].find(val => val !== null && val !== undefined) || '';
          return String(aValue).localeCompare(String(bValue));
        }
        return String(a[column.id] || '').localeCompare(String(b[column.id] || ''));
      },
      renderHeaderCell: () => (
        <div style={{ ...styles.headerCell, width: column.width }}>
          {column.label}
        </div>
      ),
      renderCell: column.renderCell,
    })
  ), [getUserRoleName, getPlantName, getAreaName, getDepartmentName, getSubAreaName]);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleRowClick = useCallback((rowId) => {
    const selectedGrouped = items.find((item) =>
      Array.isArray(item.allIds)
        ? item.allIds.includes(rowId)
        : item.id === rowId
    );

    const getOriginalRecords = async () => {
      try {
        const response = await apiClient.get("userProfile");
        const allRecords = response.data.users || [];
        const originalRecords = allRecords.filter(record =>
          selectedGrouped.allIds.includes(record.id)
        );

        setSelectedUser({
          ...selectedGrouped,
          originalRecords
        });
        setNavigateToDetail(true);
      } catch (err) {
        console.error("Error fetching original records:", err);
      }
    };

    getOriginalRecords();
  }, [items, apiClient]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value?.toLowerCase() || "");
    setCurrentPage(1);
  };

  const handlePagination = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  const getFilteredItems = () => {

    let filteredItems = items;
    console.log(filteredItems,'filteredItems')

    if (userRole !== "Business Planner") {
      filteredItems = items.filter(item => 
        item.user_email.toLowerCase() === teamsUserEmail.toLowerCase()
      );
    }

     filteredItems = filteredItems.filter((item) => {
      const matchesSearch = Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );

      const matchesFilters =
        (appliedFilters.shifts?.length === 0 || appliedFilters.shifts?.includes(item.Shift)) &&
        (appliedFilters.footprints?.length === 0 || appliedFilters.footprints?.includes(item.Footprint)) &&
        (appliedFilters.users?.length === 0 || appliedFilters.users?.includes(item.User)) &&
        (appliedFilters.downtimeMin === "" || parseInt(item.TotalDowntime) >= parseInt(appliedFilters.downtimeMin)) &&
        (appliedFilters.downtimeMax === "" || parseInt(item.TotalDowntime) <= parseInt(appliedFilters.downtimeMax)) &&
        (appliedFilters.alarmMin === "" || parseInt(item.Alarms) >= parseInt(appliedFilters.alarmMin)) &&
        (appliedFilters.alarmMax === "" || parseInt(item.Alarms) <= parseInt(appliedFilters.alarmMax));

      return matchesSearch && matchesFilters;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  };


  const getTotalPages = () => Math.ceil(items.length / itemsPerPage);

  const handleSaveData = useCallback(
    async (saveData) => {
      try {
         await Promise.all(
          saveData.updates.map(async (rowData) => {
            if (rowData.action === 'update') {
              await apiClient.put(`userProfile?id=${rowData.id}`, [rowData]);
              return { ...rowData, id: rowData.id };
            } else {
              const response = await apiClient.post("userProfile", [rowData]);
              return { ...rowData, id: response.data.id };
            }
          })
        );

        await fetchItems();
        setIsModalOpen(false);
      } catch (err) {
        console.error("Error saving data:", err);
        setErrorMessage("Failed to save data. Please try again.");
      }
    },
    [apiClient, fetchItems]
  );

  const renderPagination = () => {
    const totalPages = getTotalPages();
    return (
      <div style={styles.paginationContainer}>
        <IconButton
          iconProps={{ iconName: "ChevronLeftEnd6" }}
          ariaLabel="Go to first page"
          disabled={currentPage === 1}
          onClick={() => handlePagination(-currentPage + 1)}
          styles={styles.transparentButton}
        />
        <IconButton
          iconProps={{ iconName: "Back" }}
          ariaLabel="Go to previous page"
          disabled={currentPage === 1}
          onClick={() => handlePagination(-1)}
          styles={styles.transparentButton}
        />
        <Text style={styles.pageNumberText}>Page {currentPage} </Text>
        <IconButton
          iconProps={{ iconName: "Forward" }}
          ariaLabel="Go to next page"
          disabled={currentPage === totalPages}
          onClick={() => handlePagination(1)}
          styles={styles.transparentButton}
        />
        <IconButton
          iconProps={{ iconName: "ChevronRightEnd6" }}
          ariaLabel="Go to last page"
          disabled={currentPage === totalPages}
          onClick={() => handlePagination(totalPages - currentPage)}
          styles={styles.transparentButton}
        />
      </div>
    );
  };

  if (navigateToDetail) {
    return (
      <Navigate
        to="/user-detail"
        state={{
          user: selectedUser,
          currentPage,
          searchTerm,
          items,
          currentuser: userInfo.displayName,
          appliedFilters,
          dropdownValues
        }}
        replace
      />
    );
  }

  return (
    <div style={{ padding: "20px", background: "white" }}>
      {isLoading && <Spinner label="Loading users..." size="large" />}
      {!isLoading && errorMessage && (
        <MessageBar intent="error" isMultiline={false} dismissButtonAriaLabel="Close">
          {errorMessage}
        </MessageBar>
      )}
      {!isLoading && !errorMessage && (
        <>
          <div style={styles.headerContainer}>
            <Label style={{ fontWeight: 400, fontSize: "18px", textAlign: "left", fontFamily: "Roboto", color: "#3B3B39" }}>User Profiles</Label>
            <div style={styles.searchContainer}>
              <SearchBox
                onChange={handleSearch}
                value={searchTerm}
                styles={styles.searchBox}
                placeholder="Find"
              />

              <PrimaryButton styles={buttonStyles.primary} text='New User' onClick={handleOpenModal} />
            </div>
          </div>
          <AddUserModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={(userData) => {
              handleSaveData(userData);
              setIsModalOpen(false);
            }}
            items={items}
          />
          {items.length === 0 ? (
            <div style={styles.noDataContainer}>No data available</div>
          ) : (
            <>

              <DataGrid
                items={getFilteredItems()}
                columns={createColumns()}
                sortable
                getRowId={(item) => item.Id}
                focusMode="composite"
                style={{ minWidth: "550px", overflowX: "auto" }}
              >
                <DataGridHeader style={{ backgroundColor: "#F5F5F5" }}>
                  <DataGridRow>
                    {({ renderHeaderCell }) => (
                      <DataGridHeaderCell style={styles.headerCellContainer} sortIcon={<ChevronDown20Regular />}>
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    )}
                  </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                  {({ item, rowId }) => (
                    <DataGridRow
                      style={styles.dataRow}
                      key={rowId}
                      onClick={() => handleRowClick(item.id)}
                    >
                      {({ renderCell }) => (
                        <DataGridCell style={styles.dataCell}>
                          {renderCell(item)}
                        </DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </DataGrid>
              <div style={styles.footerContainer}>{renderPagination()}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = {

  emailCell: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#242424",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    cursor: "pointer",
    padding: "4px 0",
  },

  tooltipContent: {
    padding: "8px",
    maxWidth: "300px",
    wordBreak: "break-word",
    fontSize: "12px",
    fontFamily: "Segoe UI",
  },
  controlsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  headerCellContainer: {
    padding: "12px 8px",
    borderRight: "1px solid #E1E1E1",
  },
  
  headerCell: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Segoe UI",
    textAlign: "left",
    lineHeight: "16px",
    color: "#242424",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  dataRow: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#242424",
    cursor: "pointer", 
    transition: "background-color 0.1s ease", 
    ":hover": {
      backgroundColor: "#f5f5f5", 
    },
  },

  dataCell: {
    padding: "8px",
    borderRight: "1px solid #E1E1E1",
    minHeight: "48px",
  },

  cellContent: {
    padding: "8px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },

  wrappingCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    width: "100%",
    minHeight: "20px",
    lineHeight: "1.4",
  },

  filterContainer: {
    position: "relative",
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchContainer: {
    display: "flex",
    gap: "10px",
  },
  searchBox: {
    width: "200px",
  },
  noDataContainer: {
    padding: "20px",
    textAlign: "center",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    marginLeft: '86%'
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center"
  },
  pageNumberText: {
    padding: "0 5px",
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    fontSize: '20px',
    color: '#888',
    marginLeft: '10px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: 'calc(100vw - 33px)'
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '15px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'box-shadow 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContent: {
    flexGrow: 1,
    width: '100%',
  },
  labelValueGrid: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: '5px',
    marginBottom: '5px',
  },
  label: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  value: {
    textAlign: 'left',
  },
  transparentButton: {
    root: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      size: 'medium',
    },
    rootDisabled: {
      backgroundColor: 'transparent',
      color: 'black',
      cursor: 'default',
    },
    rootHovered: {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
};

const buttonStyles = {
  primary: {
    root: { backgroundColor: '#00439D', borderColor: '#00439D' },
    rootHovered: { backgroundColor: '#003377', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#002355', borderColor: '#002355' },
  },
  default: {
    root: { backgroundColor: '#FFFFFF', borderColor: '#00439D' },
    rootHovered: { backgroundColor: '#F0F0F0', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#E0E0E0', borderColor: '#002355' },
  }
};

export default UserProfile;
