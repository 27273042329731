import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TeamsFxContext } from "../Context";
import {
  Button,
  Combobox,
  Option,
  Label,
  Input,
  Switch,
  Spinner,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Text
} from "@fluentui/react-components";
import BackdropLoader from "./BackdropLoader";

const DowntimeAlertModal = ({ isOpen, onClose, onSave, mode = 'create', userData = null }) => {
  const { teamsUserCredential, apiClient } = useContext(TeamsFxContext);
  const [currentStep, setCurrentStep] = useState(mode === 'edit' ? 2 : 1);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserText, setSelectedUserText] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [teamsUserEmail, setTeamsUserEmail] = useState("");
  const [formData, setFormData] = useState({
    username: userData?.name || '',
    email: userData?.email || '',
    role: userData?.role || '',
    alert_threshold: userData?.alert_threshold_min || '30',
    time: userData?.occurrence_threshold_min || '15',
    occurences: userData?.occurrences || '3',
    alerts_enabled: userData?.active ?? true
  });

  const timeOptions = [
    { key: '15', text: '15 minutes' },
    { key: '30', text: '30 minutes' },
    { key: '45', text: '45 minutes' },
    { key: '60', text: '60 minutes' }
  ];

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const userInfo = await teamsUserCredential.getUserInfo();
      setTeamsUserEmail(userInfo?.preferredUserName);
      const response = await apiClient.get('getUserList');
      const responseOne = await apiClient.get('getUserListUnFiltered');
      const currentUser = responseOne.data.users.find(user => 
        user.user_email === userInfo?.preferredUserName
      );
      let usersList = response.data.users.map(user => ({
        key: user.user_email,
        text: `${user.name} (${user.user_email})`,
        user: {
          username: user.name,
          email: user.user_email,
          role: user.role
        }
      }));
      if (currentUser?.role !== "Business Planner") {
        usersList = usersList.filter(user => 
          user.user.email.toLowerCase() === teamsUserEmail.toLowerCase()
        );
      }
      setUsers(usersList);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  }, [teamsUserCredential, apiClient, teamsUserEmail]);

  useEffect(() => {
    if (isOpen) {
      if (mode !== 'edit') {
        fetchUsers();
      } else if (userData) {
        setFormData({
          username: userData.name,
          email: userData.email,
          role: userData.role,
          alert_threshold: userData.alert_threshold_min,
          time: userData.occurrence_threshold_min,
          occurences: userData.occurrences,
          alerts_enabled: userData.active
        });
      }
    }
  }, [isOpen, mode, userData, fetchUsers]);

  // Rest of the component remains unchanged
  const handleUserSelect = (event, data) => {
    const selectedUser = users.find(u => u.key === data.optionValue);
    if (selectedUser) {
      setSelectedUser(selectedUser);
      setSelectedUserText(selectedUser.text);
      setFormData(prev => ({
        ...prev,
        username: selectedUser.user.username,
        email: selectedUser.user.email,
        role: selectedUser.user.role
      }));
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleTimeSelect = (event, data) => {
    setFormData(prev => ({
      ...prev,
      time: data.optionValue
    }));
  };

  const handleNext = () => {
    if (selectedUser) {
      setCurrentStep(2);
    }
  };

  const handleClose = () => {
    setCurrentStep(mode === 'edit' ? 2 : 1);
    if (mode !== 'edit') {
      setSelectedUser(null);
      setSelectedUserText('');
      setSearchQuery('');
      setFormData({
        username: '',
        email: '',
        role: '',
        alert_threshold: '30',
        time: '15',
        occurences: '3',
        alerts_enabled: true
      });
    }
    onClose();
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const dataToSave = mode === 'edit' 
        ? { ...formData, id: userData.id }
        : formData;

      await onSave(dataToSave);
      handleClose();
    } catch (error) {
      console.error('Error saving alert:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const renderStep1 = () => (
    <DialogContent className="modal-content">
      <div className="field-container">
      {users.length === 0 ? (
          <Text>Users already added to alerts</Text>
        ) : (
          <Combobox
            placeholder="Search and select user"
            value={selectedUserText || searchQuery}
            onOptionSelect={handleUserSelect}
            onChange={(event) => {
              setSearchQuery(event.target.value);
              setSelectedUserText('');
              setSelectedUser(null);
            }}
            appearance="outline"
          >
            {users
              .filter(user => 
                user.text.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((user) => (
                <Option key={user.key} value={user.key}>
                  {user.text}
                </Option>
              ))
            }
          </Combobox>
        )}
      </div>

      <DialogActions>
        <Button appearance="secondary" onClick={handleClose}>Cancel</Button>
        <Button appearance="primary" onClick={handleNext} disabled={!selectedUser}>
          Next
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderStep2 = () => (
    <DialogContent className="modal-content">
      <div className="section user-info">
        <div className="user-info-grid">
          <div className="field-container full-width">
            <Label>Name</Label>
            <Input 
              value={formData.username}
              readOnly
              className="readonly-input"
            />
          </div>
          <div className="email-role-row">
            <div className="field-container email-field">
              <Label>Email</Label>
              <Input 
                value={formData.email}
                readOnly
                className="readonly-input"
              />
            </div>
            <div className="field-container role-field">
              <Label>Role</Label>
              <Input 
                value={formData.role}
                readOnly
                className="readonly-input"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="field-container">
          <Label>Alert Threshold (minutes)</Label>
          <Input
            type="number"
            min={1}
            max={1440}
            value={formData.alert_threshold}
            onChange={handleInputChange('alert_threshold')}
          />
        </div>
      </div>

      <div className="section">
        <Label className="section-title">Occurrence Threshold</Label>
        <div className="occurrence-grid">
          <div className="field-container">
            <Label>Time (minutes)</Label>
            <Combobox
              value={`${formData.time} minutes`}
              onOptionSelect={handleTimeSelect}
              appearance="outline"
            >
              {timeOptions.map((option) => (
                <Option key={option.key} value={option.key}>
                  {option.text}
                </Option>
              ))}
            </Combobox>
          </div>
          <div className="field-container">
            <Label>Occurrences</Label>
            <Input
              type="number"
              min={1}
              max={100}
              value={formData.occurences}
              onChange={handleInputChange('occurences')}
            />
          </div>
        </div>
      </div>

      <div className="section toggle-section">
        <Switch
          label="Alerts Enabled"
          checked={formData.alerts_enabled}
          onChange={(event) => {
            setFormData(prev => ({
              ...prev,
              alerts_enabled: event.target.checked
            }));
          }}
        />
      </div>

      <DialogActions>
        {mode !== 'edit' && (
          <Button appearance="secondary" onClick={() => setCurrentStep(1)}>
            Back
          </Button>
        )}
        <Button appearance="secondary" onClick={handleClose}>Cancel</Button>
        <Button appearance="primary" onClick={handleSave}>Save</Button>
      </DialogActions>
    </DialogContent>
  );

  return (
    <Dialog 
      open={isOpen} 
      onOpenChange={(event, data) => !data.open && handleClose()}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {mode === 'edit' ? 'Edit Alert' : 'New Alert'}
          </DialogTitle>
          {isLoading ? (
            <Spinner label="Loading users..." />
          ) : (
            mode === 'edit' ? renderStep2() : (currentStep === 1 ? renderStep1() : renderStep2())
          )}
        </DialogBody>
      </DialogSurface>
      {isSaving && <BackdropLoader />}
    </Dialog>
  );
};

const styles = `
  .modal-content {
    padding: 20px;
  }

  [data-dialog-surface] {
    min-width: 1200px !important;
    max-width: 1400px !important;
  }

  @media (max-width: 1400px) {
    [data-dialog-surface] {
      min-width: 90vw !important;
      max-width: 95vw !important;
    }
  }

  .section {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E1E1E1;
  }

  .section:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .section-title {
    font-weight: 600;
    margin-bottom: 12px;
    display: block;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
  }

  .user-info-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .email-role-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
  }

  .email-field {
    width: 100%;
  }

  .role-field {
    width: 100%;
  }

  .occurrence-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .readonly-input {
    background-color: #F5F5F5 !important;
    color: #666666 !important;
  }

  .readonly-input:hover {
    background-color: #F5F5F5 !important;
  }

  .toggle-section {
    margin-top: 24px;
  }

  .modal-content label {
    font-weight: 500;
    color: #242424;
  }

  .full-width {
    width: 100%;
  }
`;

const styleSheet = document.createElement('style');
styleSheet.textContent = styles;
document.head.appendChild(styleSheet);

export default DowntimeAlertModal;