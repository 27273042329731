import React, { useContext, useState, useCallback } from "react";
import { Stack, Text, Label, Separator, DefaultButton, IconButton } from '@fluentui/react';
import { useLocation, useNavigate } from "react-router-dom";
import { TeamsFxContext } from "../Context";
import EditCommentModal from "../modals/CommentModal";

function CommentDetail() {
    const location = useLocation();
    const navigate = useNavigate();    
    // Get the apiClient from the context
    const { apiClient } = useContext(TeamsFxContext);
    const { comment, currentPage, searchTerm, items, currentuser, appliedFilters } = location.state || {};

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localItems, setLocalItems] = useState(items || []);
    const [localComment, setLocalComment] = useState(comment);

    // Navigate back with preserved state
    const handleBackClick = useCallback(() => {
        navigate('/comment', {
            state: { currentPage, searchTerm, items: localItems, appliedFilters }
        });
    }, [navigate, currentPage, searchTerm, localItems, appliedFilters]);

    const handleEditClick = useCallback(() => setIsModalOpen(true), []);

    const handleSaveEdit = async (updatedComment) => {
        if (!apiClient) {
            console.error("API client is not available");
            return;
        }

        try {
            await apiClient.put(`userComments?id=${comment.Id}`, updatedComment);

            const updatedItems = localItems.map((item) => {
                if (item.Id === comment.Id) {
                    return {
                        ...item,
                        Comment: updatedComment.comment || item.Comment,
                        Shift: updatedComment.shift || item.Shift,
                        Footprint: updatedComment.footprint || item.Footprint,
                        User: updatedComment.user || item.User,
                    };
                }
                return item;
            });

            setLocalItems(updatedItems);
            setLocalComment(updatedItems.find(item => item.Id === comment.Id));

        } catch (err) {
            console.error("Error updating comment:", err);
        } finally {
            setIsModalOpen(false);
        }
    };

    const handleDeleteClick = async () => {
        if (!apiClient) {
            console.error("API client is not available");
            return;
        }

        try {
            await apiClient.delete(`userComments?id=${comment.Id}`);

            const newItems = localItems.filter(item => item.Id !== comment.Id);
            setLocalItems(newItems);

            // Navigate to previous page if the last item is deleted
            const newPage = (newItems.length % 10 === 0) && currentPage > 1 ? currentPage - 1 : currentPage;

            navigate('/comment', {
                state: { currentPage: newPage, searchTerm, items: newItems, appliedFilters }
            });

        } catch (err) {
            console.error("Error deleting comment:", err);
        }
    };

    // Return early only after hooks are called
    if (!localComment) return <div>No data available</div>;

    return (
        <Stack tokens={{ childrenGap: 20, padding: 20 }} styles={{ root: { backgroundColor: '#FFFFFF' } }}>
            <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                <Stack horizontal verticalAlign="center">
                    <IconButton
                        iconProps={{ iconName: 'Back' }}
                        onClick={handleBackClick}
                        title="Back"
                        ariaLabel="Back"
                        style={styles.backButton}
                    />
                    <Text style={styles.textCommon}>Back</Text>
                </Stack>
                

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton text="Edit" size="medium" onClick={handleEditClick} style={styles.button} />
                    <DefaultButton text="Delete" size="medium" onClick={handleDeleteClick} style={styles.button} />
                </Stack>
            </Stack>

            <Separator />


            <Stack tokens={{ childrenGap: 10 }}>
                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Shift</Label>
                    <Text style={styles.textCommon}>{localComment.Shift}</Text>
                </Stack>  


                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Footprint</Label>
                    <Text style={styles.textCommon}>{localComment.Footprint}</Text>
                </Stack>  

                 <Stack verticalAlign="center">
                    <Label style={styles.labelCommon} >Downtime(min)</Label>
                    <Text style={styles.textCommon}>{localComment.TotalDowntime}</Text>
                </Stack> 

                
                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Alarms</Label>
                    <Text style={styles.textCommon}>{localComment.Alarms}</Text>
                </Stack> 

                
                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>User</Label>
                    <Text style={styles.textCommon}>{localComment.User}</Text>
                </Stack> 
                                
                <Stack verticalAlign="center">
                    <Label style={styles.labelCommon}>Comment</Label>
                    <Text style={styles.textCommon}>{localComment.Comment}</Text>
                </Stack> 

            </Stack>

            <EditCommentModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveEdit}
                comment={localComment}
                user={currentuser}
                mode="edit"
            />

            <Separator />
        </Stack>
    );
}

const styles = {
    container: {
      childrenGap: 20,
      padding: 20,
      backgroundColor: '#FFFFFF'
    },
    textCommon: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: 1.5,
      textAlign: "left",
      color: "#1F1F1F",
      fontSize: 14
    },
    labelCommon: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      lineHeight: 1.5,
      textAlign: "left",
      color: "#959593",
      fontSize: 12
    },
    button: {
      border: "1px solid #0072CE"
    },
    backButton: {
      marginRight: 10,
      fontFamily: "Fabric",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 16,
      textAlign: "center",
      color: "#0072CE"
    }
  };
  

export default CommentDetail;
