const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  sttEndpoint: process.env.REACT_APP_AI_STT_ENDPOINT,
  ttsEndpoint: process.env.REACT_APP_AI_TTS_ENDPOINT,
  speechKey: process.env.REACT_APP_AI_SPEECH_KEY,
  poweBi: process.env.REACT_APP_POWER_BI,
  teamsEnv: process.env.REACT_APP_ENV
};

export default config;
