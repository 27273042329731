import React, { useState, useEffect, useContext, useCallback } from "react";
import { TeamsFxContext } from "../Context";
import { Navigate, useLocation } from "react-router-dom";
import {
  TableCellLayout,
  Label,
  DataGrid,
  DataGridBody,
  DataGridRow,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  createTableColumn,
  Spinner,
  MessageBar,
  SearchBox,
} from "@fluentui/react-components";
import { ChevronDown20Regular } from "@fluentui/react-icons";
import { PrimaryButton } from "@fluentui/react";
import DowntimeAlertModal from "../modals/DowntimeAlertModal";
import BackdropLoader from "../modals/BackdropLoader";

const DowntimeAlerts = () => {
  const { teamsUserCredential, apiClient } = useContext(TeamsFxContext);
  const location = useLocation();

  const [userInfo, setUserInfo] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage] = useState(1);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [navigateToDetail, setNavigateToDetail] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [teamsUserEmail, setTeamsUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");

  const fetchAlerts = useCallback(async () => {
    try {
      const response = await apiClient.get("downtimeAlerts");
      setAlerts(response.data.alerts || []);
    } catch (err) {
      console.error("Error fetching alerts:", err);
      setErrorMessage("Failed to fetch alerts. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

  const fetchUsers = useCallback(async (userEmail) => {
    try {
      const response = await apiClient.get('getUserListUnFiltered');
      const currentUser = response.data.users.find(user => 
        user.user_email === userEmail
      );
      console.log(currentUser, 'current');
      console.log(currentUser?.role);
      setUserRole(currentUser?.role || "");
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [apiClient]);

  useEffect(() => {
    const initData = async () => {
      try {
        const userInfo = await teamsUserCredential.getUserInfo();
        console.log(userInfo, 'user');
        setTeamsUserEmail(userInfo?.preferredUserName);
        setUserInfo(userInfo);
        
        await fetchUsers(userInfo?.preferredUserName);

        const state = location.state;
        if (state?.items) {
          setAlerts(state.items);
          setSearchTerm(state.searchTerm || "");
        } else {
          await fetchAlerts();
        }
      } catch (err) {
        console.error("Error initializing:", err);
        setErrorMessage("Error fetching data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    initData();
  }, [teamsUserCredential, location.state, fetchAlerts, fetchUsers]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value?.toLowerCase() || "");
  };

  const getFilteredAlerts = () => {
    console.log(alerts, 'alerts', userRole);
    let filteredAlerts = alerts;

    if (userRole !== "Business Planner") {
      filteredAlerts = alerts.filter(alert => 
        alert.email.toLowerCase() === teamsUserEmail.toLowerCase()
      );
    }

    return filteredAlerts.filter((alert) =>
      Object.values(alert).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const handleAddAlert = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveAlert = async (alertData) => {
    try {
      setIsModalOpen(false);
      setIsSaving(true);
      await apiClient.post("downtimeAlerts", alertData);
      await fetchAlerts();
      setIsSaving(false);
    } catch (err) {
      console.error("Error saving alert:", err);
      setErrorMessage("Failed to save alert. Please try again.");
    }
  };

  const handleRowClick = (item) => {
    setSelectedAlert(item);
    setNavigateToDetail(true);
  };

  const createColumns = () => [
    {
      columnId: "username",
      label: "User Name",
      renderCell: (item) => (
        <TableCellLayout>
          {item.name}
        </TableCellLayout>
      )
    },
    {
      columnId: "email",
      label: "Email",
      renderCell: (item) => (
        <TableCellLayout>
          {item.email}
        </TableCellLayout>
      )
    },
    {
      columnId: "role",
      label: "Role",
      renderCell: (item) => (
        <TableCellLayout>
          {item.role}
        </TableCellLayout>
      )
    },
    {
      columnId: "alert_threshold",
      label: "Alert Threshold (mins)",
      renderCell: (item) => (
        <TableCellLayout>
          {item.alert_threshold_min}
        </TableCellLayout>
      )
    },
    {
      columnId: "time",
      label: "Time (mins)",
      renderCell: (item) => (
        <TableCellLayout>
          {item.occurrence_threshold_min}
        </TableCellLayout>
      )
    },
    {
      columnId: "occurences",
      label: "Occurrences",
      renderCell: (item) => (
        <TableCellLayout>
          {item.occurrences}
        </TableCellLayout>
      )
    },
    {
      columnId: "alerts_enabled",
      label: "Status",
      renderCell: (item) => (
        <TableCellLayout>
          {item.active ? "Enabled" : "Disabled"}
        </TableCellLayout>
      )
    }
  ].map((column) =>
    createTableColumn({
      columnId: column.columnId,
      compare: (a, b) => {
        return String(a[column.columnId] || '').localeCompare(String(b[column.columnId] || ''));
      },
      renderHeaderCell: () => <span style={styles.headerCell}>{column.label}</span>,
      renderCell: column.renderCell,
    })
  );

  if (navigateToDetail) {
    return (
      <Navigate
        to="/downtime-alert-details"
        state={{
          alert: selectedAlert,
          currentPage,
          searchTerm,
          items: alerts,
          currentuser: userInfo.displayName
        }}
        replace
      />
    );
  }

  return (
    <div style={{ padding: "20px", background: "white" }}>
      {isLoading && <Spinner label="Loading alerts..." size="large" />}

      {!isLoading && errorMessage && (
        <MessageBar intent="error" isMultiline={false} dismissButtonAriaLabel="Close">
          {errorMessage}
        </MessageBar>
      )}

      {!isLoading && !errorMessage && (
        <>
          <div style={styles.headerContainer}>
            <Label style={styles.title}>Downtime Alerts</Label>
            <div style={styles.controlsContainer}>
              <SearchBox
                placeholder="Search alerts"
                onChange={handleSearch}
                value={searchTerm}
                styles={styles.searchBox}
              />
              <PrimaryButton
                styles={buttonStyles.primary}
                text="New Alert"
                onClick={handleAddAlert}
              />
            </div>
          </div>

          {alerts.length === 0 ? (
            <div style={styles.noDataContainer}>No alerts available</div>
          ) : (
            <DataGrid
              items={getFilteredAlerts()}
              columns={createColumns()}
              sortable
              getRowId={(item) => item.id}
              focusMode="composite"
              style={{ minWidth: "550px" }}
            >
              <DataGridHeader style={{ backgroundColor: "#F5F5F5" }}>
                <DataGridRow>
                  {({ renderHeaderCell }) => (
                    <DataGridHeaderCell sortIcon={<ChevronDown20Regular />}>
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  )}
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody>
                {({ item, rowId }) => (
                  <DataGridRow
                    style={styles.row}
                    key={rowId}
                    onClick={() => handleRowClick(item)}
                    className="clickable-row"
                  >
                    {({ renderCell }) => (
                      <DataGridCell>
                        {renderCell(item)}
                      </DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
          )}

          {!isSaving && <DowntimeAlertModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveAlert}
          />}
          {isSaving && <BackdropLoader />}
        </>
      )}
    </div>
  );
};

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    alignItems: "center",
  },
  controlsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  title: {
    fontWeight: 400,
    fontSize: "18px",
    textAlign: "left",
    fontFamily: "Roboto",
    color: "#3B3B39"
  },
  headerCell: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Segoe UI",
    textAlign: "left",
    lineHeight: "16px",
    color: "#242424",
  },
  row: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#242424",
    cursor: "pointer",
  },
  noDataContainer: {
    padding: "20px",
    textAlign: "center",
  },
  searchBox: {
    width: "200px",
  },
};

const buttonStyles = {
  primary: {
    root: { backgroundColor: '#00439D', borderColor: '#00439D' },
    rootHovered: { backgroundColor: '#003377', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#002355', borderColor: '#002355' },
  }
};

const additionalStyles = `
  .clickable-row:hover {
    background-color: #f5f5f5;
    transition: background-color 0.2s ease;
  }
`;

const styleSheet = document.createElement('style');
styleSheet.textContent = additionalStyles;
document.head.appendChild(styleSheet);

export default DowntimeAlerts;